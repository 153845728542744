import React from "react";

function CalculateAge(Birthday) {
  const BirthDate = new Date(Birthday);
  const CurrentDate = new Date();

  let Age = CurrentDate.getFullYear() - BirthDate.getFullYear();

  if (
    CurrentDate.getMonth() < BirthDate.getMonth() ||
    (CurrentDate.getMonth() === BirthDate.getMonth() &&
      CurrentDate.getDate() < BirthDate.getDate())
  ) {
    Age--;
  }

  return Age;
}

const Introduction = () => {
  let Age = CalculateAge("2009-12-18");
  // let Age = CalculateAge("2004-08-21");

  return (
    <>
      <h1
        data-aos="fade-down"
        className="text-4xl font tracking-tight font-extrabold text-white sm:text-6xl mb-4"
      >
        <span className="hidden md:inline-block">Hello, my name is</span>
        <span className="inline-block md:hidden">Hey, I'm</span>{" "}
        <span className="md:inline-block text-purple-700 transform transition ease-in-out duration-400 hover:scale-110 hover:-translate-y-1 hover:translate-x-1">
          {/* Alex */}
          Hayden
        </span>
      </h1>
      <p
        data-aos="fade-down"
        data-aos-delay="50"
        className="text-gray-400 font-bold text-xl max-w-4xl mb-4"
      >
        {/* I'm a {Age} year-old full stack developer from the US, currently
        studying Computer Science at the University of Chicago through an
        associates degree. */}

        I'm a {Age} year-old full stack developer from the US, hoping to
        study Computer Science at the University of Chicago through an
        associates degree.
      </p>

      <div
        data-aos="fade-down"
        data-aos-delay="100"
        class="flex flex-wrap flex-row gap-4"
      ></div>
    </>
  );
};

export default Introduction;
