import React, { useEffect, useState } from "react";

const ListeningStatus = ({Song}) => {
  return Song ? (
    <p className="text-gray-400 font-bold text-xl max-w-2xl last:pb-16 last:mb-0 mb-2 text-right mx-auto mr-0">
      I'm currently listening to{' '}
      <a target="_blank" href={Song.link} rel="noopener noreferrer">
        <span className="font-extrabold text-gray-200">{Song.name}</span> by{' '}
        <span className="font-extrabold text-gray-200">{Song.artists}</span>
      </a>{' '}
      on Spotify.
    </p>
  ) : (
    <p className="text-gray-400 font-bold text-xl max-w-2xl last:pb-16 last:mb-0 mb-2 text-right mx-auto mr-0">
      I'm not listening to anything on{' '}
      <a
        href="https://open.spotify.com/user/31ap6ep46go7twlei3c2pe5ipmwe"
        rel="noopener noreferrer"
        target="_blank"
        className="font-extrabold text-gray-200"
      >
        Spotify
      </a>{' '}
      right now.
    </p>
  );
}

const InterestingStuff = () => {
  const [Loading, SetLoading] = useState(true);
  const [TimeSpent, SetTimeSpent] = useState("");
  const [StreakData, SetStreakData] = useState(null);
  const [NowPlaying, SetNowPlaying] = useState(null);

  useEffect(() => {
    const FetchData = async () => {
      try {
        const Response = await fetch(
          "https://portfolio-tools.iipotatoflames.workers.dev/time-coded"
        );

        if (!Response.ok) {
          throw new Error(`HTTP error! Status: ${Response.status}`);
        }

        const Data = await Response.json();

        const TotalSeconds = Math.round(Data.seconds);
        const Hours = Math.floor(TotalSeconds / 3600);
        const Minutes = Math.floor((TotalSeconds % 3600) / 60);
        const Seconds = TotalSeconds % 60;

        SetTimeSpent(
          `${Hours} hours, ${Minutes} minutes, and ${Seconds} seconds`
        );
        SetLoading(false);
      } catch (error) {
        console.error(error);
        SetTimeSpent("0 hours, 0 minutes, and 0 seconds");
        SetLoading(false);
      }
    };

    const FetchStreak = async () => {
      const Response = await fetch(
        "https://portfolio-tools.iipotatoflames.workers.dev/commit-streak"
      );

      if (!Response.ok) {
        throw new Error(`HTTP error! Status: ${Response.status}`);
      }

      const Data = await Response.json();

      SetStreakData(Data.data);
    };

    const FetchNowPlaying = async () => {
      const Response = await fetch(
        "https://portfolio-tools.iipotatoflames.workers.dev/now-playing"
      );

      if (!Response.ok) {
        throw new Error("Error fetching Spotify data");
      }

      const Data = await Response.json();

      if (Data.data) {
        SetNowPlaying(Data.data);
      } else {
        SetNowPlaying(null);
      }
    };

    FetchData();
    FetchStreak();
    FetchNowPlaying();

    const NowPlayingInterval = setInterval(FetchNowPlaying, 60000); // 60000ms = 60s

    return () => clearInterval(NowPlayingInterval);
  }, []);

  return (
    <>
      <h1
        data-aos="fade-down"
        data-aos-delay="200"
        className="text-4xl tracking-tight font-extrabold text-white sm:text-6xl pt-16 mb-4 text-right"
      >
        Interesting Stuff
      </h1>
      <div data-aos="fade-down" data-aos-delay="250">
        <p className="text-gray-400 font-bold text-xl max-w-2xl last:pb-16 last:mb-0 mb-2 text-right mx-auto mr-0">
          In case you were wondering, I've spent{" "}
          {Loading ? (
            "Loading..."
          ) : (
            <a
              href="https://wakatime.com/@vertical"
              rel="noreferrer noopener"
              target="_blank"
              className="font-extrabold text-gray-200"
            >
              {TimeSpent}
            </a>
          )}{" "}
          developing in the last week.
        </p>
        {StreakData && (
          <p className="text-gray-400 font-bold text-xl max-w-2xl last:pb-16 last:mb-0 mb-2 text-right mx-auto mr-0">
            Also, I have a commit streak of{" "}
            <span className="font-extrabold text-gray-200">
              {StreakData.commits}
            </span>
            , across{" "}
            <span className="font-extrabold text-gray-200">
              {StreakData.days} {StreakData.days === 1 ? "day" : "days"}
            </span>
            {"."}
          </p>
        )}

        <ListeningStatus Song={NowPlaying} />
      </div>
    </>
  );
};

export default InterestingStuff;
