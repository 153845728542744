import React, { useEffect, useState } from "react";

function ScrollButton() {
  const [opacity, setOpacity] = useState(1);

  useEffect(() => {
    window.onscroll = () => {
      const yOffset = window.pageYOffset;
      const triggerOffset = window.innerHeight / 2.75;

      if (yOffset > triggerOffset) {
        setOpacity(0);
      } else if (yOffset <= triggerOffset) {
        setOpacity(1);
      }
    };
  }, []);

  const handleClick = () => {
    const scrollHeight = window.innerHeight + 48;
    window.scrollBy({
      top: scrollHeight,
      behavior: "smooth",
    });
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      className="mx-auto relative transition ease-in-out -mt-14 animate-bounce w-9 h-9 text-white cursor-pointer vertical:hidden"
      style={{ opacity }}
      onClick={handleClick}
      >
      <path
        fill-rule="evenodd"
        d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z"
        clip-rule="evenodd"
      ></path>
    </svg>
  );
}

export default ScrollButton;
