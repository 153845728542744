import "./App.css";

import AOS from "aos";
import "aos/dist/aos.css";

import { useEffect } from "react";

import Introduction from "./components/Introduction";
import GenerateButton from "./components/GenerateButton";
import InterestingStuff from "./components/InterestingStuff";

import Languages from "./components/Languages";
// import Projects from "./components/Projects";
import Music from "./components/Music";

function App() {
  useEffect(() => {
    AOS.init();
  }, [])

  return (
    <>
      <div className="relative pt-12 md:pt-32 h-screen select-none min-h-128">
        <div className="relative mx-auto max-w-7xl px-10 sm:px-6 lg:px-8 xl:px-4 sm:static">
          <Introduction/>
          <InterestingStuff/>
        </div>
      </div>

      <GenerateButton/>

      <div className="relative pt-24 pb-64 sm:pb-48 select-none">
        <div className="relative mx-auto max-w-7xl px-10 sm:px-6 lg:px-8 xl:px-4 2xl:px-4 sm:static">
            <Languages/>
            {/* <Projects/> */}
            <Music/>
        </div>
      </div>
    </>
  );
}

export default App;
