import React, { useEffect, useState } from "react";

function TrackComponent({ Title, Artists, Link, Image }) {
  return (
    <a
      target="_blank"
      rel="noreferrer"
      href={Link}
      className="group cursor-pointer group bg-gray-800 bg-opacity-75 border border-gray-700 text-white rounded-xl p-2"
    >
      <div className="flex flex-row gap-2">
        <img alt="album cover" className="h-16 rounded-lg" src={Image} />
        <div className="flex justify-center flex-col">
          <h4 className="font-bold text-white leading-none group-hover:text-green-500 transition duration-250 mb-1">
            {Title}
          </h4>
          <p className="font-bold text-sm text-gray-300 leading-none">{Artists}</p>
        </div>
      </div>
    </a>
  );
}

const Music = () => {
  const [TrackData, SetTrackData] = useState(null);

  useEffect(() => {
    const FetchData = async () => {
      try {
        const Response = await fetch(
          "https://portfolio-tools.iipotatoflames.workers.dev/top-tracks"
        );

        if (!Response.ok) {
          throw new Error(`HTTP error! Status: ${Response.status}`);
        }

        const Data = await Response.json();

        SetTrackData(Data.tracks);
      } catch (error) {
        console.error(error);
        SetTrackData([]);
      }
    };

    FetchData();
  }, []);

  return (
    <>
      <h1 className="text-4xl tracking-tight font-extrabold text-white sm:text-6xl mb-4 text-right">
        Music
      </h1>
      <p className="text-gray-400 font-bold text-xl max-w-4xl mb-5 text-right mx-auto mr-0">
        Below you can find the tracks I've been listening to the most on{" "}
        <a
          href="https://open.spotify.com/user/31ap6ep46go7twlei3c2pe5ipmwe"
          target="_blank"
          rel="noreferrer"
          className="text-green-500"
        >
          Spotify
        </a>{" "}
        over the past month
      </p>
      <div className="grid lg:grid-cols-4 grid-cols-1 gap-5">
        {TrackData && TrackData.map((track, index) => (
          <TrackComponent
            key={index}
            Title={track.name}
            Artists={track.artists}
            Link={track.link}
            Image={track.image}
          />
        ))}
      </div>
    </>
  );
};

export default Music;
